import Vue from 'vue'
import App from './App.vue'
import router from './router'

//bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

const isProduction = process.env.NODE_ENV === 'production';
Vue.config.productionTip = isProduction
Vue.config.devtools = !isProduction

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import { LayoutPlugin } from 'bootstrap-vue'
Vue.use(LayoutPlugin)

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import { CardPlugin } from 'bootstrap-vue'
Vue.use(CardPlugin)

import { NavbarPlugin } from 'bootstrap-vue'
Vue.use(NavbarPlugin)

//Vuex store
import store from './store/index'
store.commit('setProd', isProduction);

//axios
import axios from 'axios'
if (isProduction) {
  axios.defaults.baseURL = 'https://oism-api.ir-elektro.de/';
} else {
  axios.defaults.baseURL = 'http://localhost:3000';
}
axios.defaults.withCredentials = true;

//logger
import VueLogger from 'vuejs-logger';

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel : isProduction ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
});

//i18n
import i18n from './i18n'

//Vue-Cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expire: '1y', path: '/', domain: '', secure: true, sameSite: 'Strict'})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

