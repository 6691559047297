import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
export default new Vuex.Store({
    state: {
      userObj: null,
      isProduction: true
    },
    getters: {
        loggedIn: state => {
          return state.userObj != null
        }
    },
    mutations: {
      increment (state, int) {
        state.count += int
      },
      setUserObj(state, obj) {
          state.userObj = obj
      },
      setProd(state, obj) {
        state.isProduction = obj;
      }
    },
    strict: debug,
  })
  //state.commit('increment', 55)

