<template>
  <div id="search">
    <b-form class="form-inline" @submit="onSearch">
      <b-form-input class="col-md-9" :state="queryState" v-model="searchQuery" autofocus></b-form-input>
      <div class="col-md-3">
        <b-button type="submit" variant="primary">{{ $t('common.submit') }}</b-button>
      </div>
    </b-form>
    <b-table font-size="8" size="sm"  :items="searchResults" :fields="fields" head-variant="light">
      <template v-slot:cell(actions)="row">
        <b-button variant="secondary" @click="openShop(row.item)">[Shop]</b-button>
        <b-button variant="primary" @click="onAdd(row.item)">{{ $t('common.select') }}</b-button>
      </template>
    </b-table>
    {{ $t('vendor.articles') }}: {{ searchResults.length }} // Page {{ page }} / {{ totalPages }}
    <b-button variant="primary" :disabled="!backEnabled" @click="onTurnPage(-1)">&Lt;</b-button>
    <b-button variant="primary" :disabled="!nextEnabled" @click="onTurnPage(1)">&Gt;</b-button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ArticleSearch",
  props: {},

  data() {
    return {
      fields: [
        { key: "articleId", label: "ID" },
        { key: "description1", label: this.$t('articles.desc1') },
        { key: "description2", label: this.$t('articles.desc2')  },
        { key: "packageUnit", label: this.$t('common.unit')  },
        { key: "amountPerUnit", label: this.$t('articles.amount_per_unit')  },
        { key: "price", label:  this.$t('articles.price_in_€') },
        { key: "actions", label: this.$t('common.actions')  }
      ],

      searchQuery: "",
      searchResults: [],
      page: 1,
      totalPages: 1,
      nextEnabled: false,
      backEnabled: false,
    };
  },

  computed: {
    queryState() {
      return this.searchQuery.length > 3 ? true : false;
    }
  },
  methods: {
    onAdd(row) {
      console.log(row);
      this.$emit("add-item", row);
    },

    openShop(row) {
      console.log(row);
      window.open("https://online.bruder-gruppe.de/1RTN/produkte/A/" + row.articleId);
      //https://online.bruder-gruppe.de/1RTN/produkte/A/1041853
    },

    onTurnPage(diff) {
      this.page = Number(this.page) + Number(diff)
      this.requestArticles()
    },

    onSearch(evt) {
      evt.preventDefault();

      console.log("onSearch");
      this.page = 1
      this.totalPages = 1
      this.backEnabled = false
      this.nextEnabled = false
      this.requestArticles()
    },

    requestArticles() {
      axios
        .get("/api/vendors/search", {
          params: {
            search: encodeURIComponent(this.searchQuery),
            page: this.page,
            limit: 100
          }
        })
        .then(response => {
          this.$log.info("search okay ");
          this.$log.debug(response.data.articles);
          this.searchResults = response.data.articles;

          this.page = response.data.currentPage;
          this.totalPages = response.data.totalPages;
          this.backEnabled = this.page > 1 ? true : false
          this.nextEnabled = this.page < this.totalPages ? true : false
        })
        .catch(errors => {
          this.$log.error("fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });
    }
  }
};
</script>


<style scoped>
#search {
    min-height: fit-content;
    height: auto;
}
.b-table {
    font-size: small;
}
</style>
