<template>
  <div id="main">
    <h2>Datanorm</h2>
    <b-form @submit="onSubmit">
      <b-form-group id="input-group-1" :label="$t('vendor.datanorm_filename')" label-for="input-1">
        <b-form-input :state="nameState" v-model="name" placeholder></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" :label="$t('vendor.datanorm_file')" label-for="file">
        <b-form-file
          v-model="file"
          :state="Boolean(file)"
          :placeholder="$t('common.chooseordropfile')"
          :drop-placeholder="$t('common.dropfile')"
          :browse-text="$t('common.browse')"
        ></b-form-file>
      </b-form-group>

      <b-button type="submit" variant="primary">{{ $t('common.submit') }}</b-button>
    </b-form>

    <h2>{{ $t('vendor.current_vendors') }}</h2>
    <b-table
      :items="vendorList"
      :fields="activefields"
      striped
      hover
      responsive="sm"
    >
      <template v-slot:cell(articles)="row">
        {{ row.item.articles.length }} 
      </template>
      <template v-slot:cell(actions)="row">
      
        <b-button
          size="sm"
          @click="onConfirmDelete(row.item)"
          class="mr-2"
          variant="danger"
          :disabled="true"
        >{{ $t('common.delete') }}</b-button>
      </template>
    </b-table>

    <h2>{{ $t('vendor.test_search') }}</h2>
    <b-form class="form-inline" @submit="onSearch">
      <b-form-input class="col-8" :state="queryState" v-model="searchQuery"></b-form-input>
      <b-button class="col-2" type="submit" variant="primary">{{ $t('common.submit') }}</b-button>
    </b-form>
    <div
      class="row pl-2 pb-1"
      v-for="row in searchResults"
      v-bind:key="row._id"
    >{{ row.articleId }} ## {{ row.description1 }} ## {{ row.description2 }} ## {{ row.price }} €</div>

    <h2>Test Modal Search</h2>
    <ArticleSearch v-on:add-item="onAddItemCallback($event)"></ArticleSearch>


    <!-- ONLY MODAL --> 

    <b-modal id="delete-conformation" header-bg-variant="warning"  title="Confirm"  okVariant="danger" @ok="onDelete">Do you really want to delete {{ this.vendorToDelete.uploadName }}?</b-modal>
  </div>
</template>

<script>
import axios from "axios";
import ArticleSearch from "./ArticleSearch";
import * as utils from "../utils/utils";
//import router from "../router";

export default {
  name: "DatanormView",
  components: {
    ArticleSearch
  },

  data() {
    return {
      name: "test",
      file: null,
      vendorList:  [],
      vendorToDelete: {},

      searchQuery: "",
      searchResults: [],

      activefields: [
        { key: "vendorId", label: this.$t("vendor.vendorId"), sortable: true },
        { key: "uploadName", label: this.$t("vendor.vendorName"), sortable: true },
        {
          key: "file",
          label: this.$t("common.file"),
          sortable: true
        },
        { key: "articles", label: this.$t("vendor.articles"), sortable: true },
        { key: "tenantId", label: this.$t("vendor.tenantID"), sortable: true },
        { key: "actions", label: this.$t("common.actions") }
      ]
    };
  },
  computed: {
    nameState() {
      return this.name.length > 0 ? true : false;
    },
    queryState() {
      return this.searchQuery.length > 3 ? true : false;
    }
  },
  methods: {
    onAddItemCallback(evt) {
      this.$log.debug(evt);
    },

    onSubmit(evt) {
      evt.preventDefault();

      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("file", this.file);

      axios
        .post("/api/vendors/uploadDatanorm", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          this.$log.debug("Upload done");
          this.$log.debug(response.data);
          this.$bvToast.toast("Yipii", {
            title: this.$t("vendor.upload_done"),
            variant: "success",
            solid: true
          });
        })
        .catch(errors => {
          this.$log.error("Cannot upload " + errors);
          this.showLoginFailedAlert = true;
        });
    },

    onConfirmDelete(vendor) {
      this.vendorToDelete = vendor;
      this.$bvModal.show('delete-conformation');
    },
    onDelete() {
      let vendorId = this.vendorToDelete.vendorId;
      console.log("deleting" + vendorId);

      axios
        .delete("/api/vendors/" + vendorId)
        .then(response => {
          this.$log.info("delete okay " + response);
          this.$bvToast.toast("ok", {
            title: `Delete`,
            solid: true
          });
          this.refreshTables();
        })
        .catch(errors => {
          this.$log.error("get users fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });
    },

    onSearch(evt) {
      evt.preventDefault();

      console.log("onSearch");

      axios
        .get("/api/vendors/search/" + encodeURIComponent(this.searchQuery))
        .then(response => {
          this.$log.info("search okay ");
          this.$log.debug(response.data.articles);
          this.searchResults = response.data.articles;

          this.$bvToast.toast("ok", {
            title: `Search`,
            solid: true
          });
        })
        .catch(errors => {
          this.$log.error("fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });
    },

    refreshTables() {
      axios
        .get("/api/vendors/")
        .then(response => {
          this.$log.debug("Vendors");
          this.$log.debug(response.data);
          this.vendorList = response.data.vendors;
        })
        .catch(errors => {
          if (errors.response.status == 401) return;

          this.$log.error("get users fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });
    }
  },
  mounted() {
    utils.checkForLoginAndRedirect(this.$router, this.$store);

    this.refreshTables();
  }
};
</script>

<style scoped>
#main {
  width: 85%;
  margin: auto;
}
</style>
