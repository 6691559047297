<template>
  <div class="main">
    <h2>{{ $t('common.welcome') }}</h2>
    <p>{{ $t('common.changelanguage') }}</p>

    <div class="locale-changer">
      <select v-model="$i18n.locale" v-on:change="changeLang">
        <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
      </select>
    </div>

    <p class="bottom-align-text center ">© Henry 🚀</p>
  </div>
</template>

<script>
export default {
  name: "StartPage",
  props: {
    msg: String
  },
  data() {
    return { langs: ["en", "de"] };
  },

  methods:{
    changeLang: function(){
      this.$cookies.set('user_locale',this.$i18n.locale);
    }
  },
  beforeMount() {
    if (this.$cookies.get('user_locale') != null) {
      this.$i18n.locale = this.$cookies.get('user_locale')
    } else {
      this.$i18n.locale = "de" //default lang
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
bla {
  display: inline-block;
  margin: 0 10px;
}

.bottom-align-text {
    position: absolute;
    bottom: 0;
    left: 50%;
  }
</style>
