<template>
  <div id="main">
    <h2>Login</h2>
    <b-alert v-model="showLoginFailedAlert" variant="warning">{{ $t('login.userorpwwrong') }}</b-alert>
    <div id="login">
      <b-form @submit="onSubmit" v-show="!loggedIn">
          <p class="text-left">{{ $t('login.username') }}</p>
          <b-form-input v-model="user" type="text" placeholder="Username"></b-form-input>
          <p class="text-left">{{ $t('login.password') }}</p>
          <b-form-input v-model="password" type="password"  placeholder="Password"></b-form-input>
        <b-button type="submit" variant="primary">{{ $t('common.submit') }}</b-button>
      </b-form>
    </div>
    <div v-show="loggedIn">
      Already logged in
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import router from '../router'


export default {
  name: "Login",
  data() {
    return {
        user: '',
        password: '',
        showLoginFailedAlert : false,
    };
  },
  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      let data = {
        email: this.user,
        password: this.password
      }
      axios.post('/api/auth/login', data)
        .then((response) => {
          this.$log.debug('Logged in')
          this.$log.debug(response.data)
          this.$store.commit('setUserObj', response.data)
          router.push('/')
        })
        .catch((errors) => {
          this.$log.error('Cannot log in ' + errors)
          this.showLoginFailedAlert = true;
        })
    }
  },
  mounted() {
    if (!this.$store.state.isProduction) {
      this.user  = "admin";
      this.password = "admin";
    }
  }
};
</script>

<style scoped>
#login {
  width: 30rem;
  margin: auto;
}
#main * {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
