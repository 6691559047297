<template>
  <div id="main">
    <b-navbar class="mb-3" toggleable="lg" type="dark" variant="info">
      <b-navbar-brand to="/">{{ $t('common.title') }}</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
         
            <b-nav-item exact exact-active-class="active" to="/tasklist">{{ $t('nav.tasks') }}</b-nav-item>
            <b-nav-item exact exact-active-class="active" to="/datanorm">{{ $t('nav.vendor_mgmt') }}</b-nav-item>
          
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-show="loggedIn">{{ userObj.displayName }} </b-nav-item>
          <b-nav-item v-show="loggedIn">TID: {{ userObj.activeTenant }} </b-nav-item>
          <b-nav-item to="/login" v-show="!loggedIn">Login</b-nav-item>
          <b-nav-item-dropdown right v-show="loggedIn">
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <em>User</em>
            </template>
            <b-dropdown-item to="/userview">{{ $t('nav.profile') }}</b-dropdown-item>
            <b-dropdown-item to="/admin">Admin</b-dropdown-item>
            <b-dropdown-item @click="onLogout()">{{ $t('nav.sign_out') }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import axios from "axios";
import router from "../router";

export default {
  name: "AppNavbar",
  props: {
    msg: String
  },
  data() {
    return {
      navbarWasloaded: false
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    userObj () {
      if (this.$store.state.userObj) {
        return this.$store.state.userObj
      }
      return {}
    }
  },
  methods: {
    onLogout() {
      axios
        .post("/api/auth/logout")
        .then(response => {
          this.$log.debug("Logged off");
          this.$log.debug(response);
          this.$store.commit("setUserObj", null);
          router.push("/");
        })
        .catch(errors => {
          this.$log.error("Cannot log off" + errors);
        });
    }
  },
  mounted() {
    //login check
    axios
      .get("/api/auth/loginCheck")
      .then(response => {
        this.$log.debug("Existing session found!");
        this.$log.debug(response.data);
        this.$store.commit("setUserObj", response.data);
      })
      .catch(error => {
        //ignore 401 errors,expected
        if (error.response.status != 401) {
          console.log(error)
        }
      });
      

      this.navbarWasloaded = true
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#main {
  width: 95%;
  margin: auto;
}

.active {
  font-style: normal;
  font-weight: 500;
}
</style>
