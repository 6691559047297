<template>
  <div id="main">
    <h2 class="text-center">{{ $t('tasks.taskview') }}</h2>
    <b-form @submit="onSubmit">
      <b-form-group
        :label="$t('tasks.task_name')"
        label-cols-md="2"
        label-cols-sm="3"
        label-class="taskLabel"
        label-for="taskInput"
      >
        <b-form-input id="taskInput" v-model="taskObj.taskName" type="text"></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('tasks.customer')"
        label-cols-md="2"
        label-cols-sm="3"
        label-for="costumerName"
      >
        <b-form-input id="costumerName" v-model="taskObj.customerName" type="text"></b-form-input>
      </b-form-group>
      <hr />
      
      <h6 class="mt"><b>{{ $t('tasks.items') }}</b></h6>
      <div class="ml-3 pb-1">
        <div class="row">
          <div class="col-2">{{ $t('tasks.amount') }}</div>
          <div class="col-4">{{ $t('tasks.description') }}</div>
          <div class="col-1">{{ $t('tasks.amount') }}</div>
          <div class="col-2">{{ $t('common.unit') }}</div>
          <div class="col-3">{{ $t('tasks.vendor') }}</div>
        </div>
        <div
          class="row pl-2 pb-1"
          v-for="(row, index) in taskObj.items"
          v-bind:key="row._id"
          v-bind:index="index"
        >
          <b-form-input class="col-2 addPadding" id="articleId" v-model="row.articleId" type="text"></b-form-input>
          <b-form-input class="col-4" id="description" v-model="row.description" type="text"></b-form-input>

          <b-form-input class="col-1" id="amount" v-model="row.amount" type="text"></b-form-input>
          <b-form-input class="col-2" id="unitPackageInfo" v-model="row.unitPackageInfo" type="text"></b-form-input>
          <b-form-input class="col-2" id="vendor" v-model="row.vendor" type="text"></b-form-input>
          <b-button class="col-1" @click="onDeleteArticle(index)">X</b-button>
        </div>


        <b-button variant="primary mb-1" @click="onAddArticle">{{ $t('tasks.add_item') }}</b-button>
        <b-button
          variant="primary ml-2 mb-1"
          @click="showSearchModal = true"
        >{{ $t('tasks.search_item') }}</b-button>
      </div>
      <br />
      <h6><b>{{ $t('tasks.work_time') }}</b></h6>
      <div class="ml-3 pb-1">
        <div class="row">
          <div class="col-3">{{ $t('tasks.worker') }}</div>
          <div class="col-2">{{ $t('tasks.price') }}</div>
          <div class="col-2">{{ $t('tasks.worker_time') }}</div>
        </div>
        <div
          class="row col-12 pb-1"
          v-for="(row, index) in taskObj.workTime"
          v-bind:key="row._id"
          v-bind:index="index"
        >
          <b-form-input class="col-3" id="worker" v-model="row.worker" type="text"></b-form-input>
          <b-form-input class="col-2" id="cost" v-model="row.cost" type="text"></b-form-input>
          <b-form-input class="col-2" id="time" v-model="row.time" type="text"></b-form-input>
          <b-button class="col-1 ml-2" @click="onDeleteWorkTimeItem(index)">X</b-button>
        </div>
        <b-button
          class="mb-1"
          variant="primary"
          @click="onAddWorkTimeItem"
        >{{ $t('tasks.add_work_item') }}</b-button>
      </div>

      <hr />
      <b-form-group :label="$t('tasks.drive_info')" label-for="driveInfo">
        <b-form-input id="driveInfo" v-model="taskObj.driveInfo" type="text"></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('tasks.hotel_info')" label-for="hotelInfo">
        <b-form-input id="hotelInfo" v-model="taskObj.hotelInfo" type="text"></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('tasks.invoice_no')" label-for="invoiceNumber">
        <b-form-input id="invoiceNumber" v-model="taskObj.invoiceNumber" type="text"></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('tasks.comments')" label-for="comments">
        <b-form-textarea id="comments" v-model="taskObj.comments" type="text" rows="4"></b-form-textarea>
      </b-form-group>

      <hr />
      <b-form-group :label="$t('tasks.status')" label-for="status">
        <b-form-select id="status" v-model="taskObj.status" :options="statusOptions" size="m"></b-form-select>
      </b-form-group>
      <b-form-group :label="$t('tasks.assignee')" label-for="assignee">
        <b-form-input id="assignee" v-model="taskObj.assignee" type="text"></b-form-input>
      </b-form-group>
      <br />

      <b-row>
        <b-button class="mx-auto"  type="submit" variant="primary">{{ $t('common.save') }}</b-button>
      </b-row>
      <br/>
      <b-row>
        <small class="mx-auto">Debug Task ID:{{ taskObj._id }}</small>
      </b-row>
      
    </b-form>

    <!-- ONLY MODAL -->
    <b-modal size="xl" v-model="showSearchModal" scrollable :title="$t('common.search')">
      <b-container fluid>
        <ArticleSearch v-on:add-item="onAddItemCallback($event)"></ArticleSearch>
      </b-container>

      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import router from "../router";
import ArticleSearch from "./ArticleSearch";

export default {
  name: "TaskList",
  props: {
    taskId: {
      type: String
    }
  },
  components: {
    ArticleSearch
  },
  data() {
    return {
      taskObj: {},

      showSearchModal: false,

      statusOptions: [
        { value: "Neu", text: "Neu" },
        { value: "In Arbeit", text: "In Arbeit" },
        { value: "Rechnung machen", text: "Rechnung machen" },
        { value: "Rechnung Fertig", text: "Rechnung Fertig" },
        { value: "Warten auf Zahlung", text: "Warten auf Zahlung" },
        { value: "Fertig", text: "Fertig" }
      ]
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      ///api/tasks/:id
      const data = {
        task: this.taskObj
      };
      axios
        .patch("/api/tasks/" + this.taskId, data)
        .then(response => {
          this.$log.debug("save okay " + response);
          this.$bvToast.toast(this.$t("tasks.task_saved_body"), {
            title: this.$t("tasks.task_saved"),
            solid: true
          });
        })
        .catch(errors => {
          this.$log.error("create task fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });
    },

    onAddArticle() {
      this.taskObj.items.push({
        articleId: "",
        description: "",
        amount: 0,
        vendor: ""
      });
    },

    onDeleteArticle(index) {
      this.taskObj.items.splice(index, 1);
    },

    onAddWorkTimeItem() {
      this.taskObj.workTime.push({ worker: "", time: 0 });
    },
    onDeleteWorkTimeItem(index) {
      this.taskObj.workTime.splice(index, 1);
    },

    onAddItemCallback(evt) {
      this.$log.debug(evt);
      this.showSearchModal = false;

      let item = {
        articleId: evt.articleId,
        description: evt.description1 + ", " + evt.description2,
        amount: 1,
        unitPackageInfo: evt.amountPerUnit + " " + evt.packageUnit,
        vendor: "",
        articleRef: evt._id
      };
      this.taskObj.items.push(item);
      let index = this.taskObj.items.indexOf(item);

      axios
        .get("/api/vendors/" + evt.vendorRef)
        .then(response => {
          this.$log.debug("get vendor ");
          this.$log.debug(response);

          this.taskObj.items[index]["vendor"] = response.data.vendor.uploadName;
        })
        .catch(errors => {
          this.$log.error("get vendor fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });
    }

    /*
           articleId: Number,
    description: String,
    amount: Number,

    vendor: String

    */
  },
  mounted() {
    console.log(this.$route.params);
    console.log(JSON.stringify(this.taskId));
    if (this.taskId === "") {
      //no id, no job
      router.push("/");
      return;
    }

    this.$log.debug(this.taskId);
    axios
      .get("api/tasks/" + this.taskId)
      .then(response => {
        this.$log.debug(response);
        this.taskObj = response.data.task;
      })
      .catch(errors => {
        this.$log.error("get task fail " + errors);
        this.$bvToast.toast(errors.message, {
          title: `API Error`,
          variant: "danger",
          solid: true
        });
      });
  }
};
</script>

<style scoped>
#main {
  width: 80%;
  text-align: left;
  margin-left: 10%;
}

.articleId {
  padding: 50px;
}

.taskLabel {
  font-weight: 500;
}

#taskLabel {
  font-weight: 800;
}
</style>
