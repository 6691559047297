<template>
  <div id="main">
    <h2>User Mgmt</h2>
    <b-table :items="users" :fields="userfields" :busy="isBusy" striped hover responsive="sm">
      <template v-slot:cell(isGlobalAdmin)="row">
        <b-checkbox v-model="row.item.isGlobalAdmin" disabled></b-checkbox>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button size="sm" @click="onEditUser(row.item)" class="mr-2">Edit</b-button>
        <b-button size="sm" @click="onDeleteUser(row.item, $evt)" class="mr-2" variant="danger">Delete</b-button>
      </template>
    </b-table>
    <b-button size="sm" @click="showUserModal=true; mode='create'" class="mr-2" variant="success">Create User</b-button>

    <br />
    <hr />
    <h2>Company Mgmt</h2>
    <b-table :items="tenants" :fields="tenantfields" :busy="isBusy" striped hover responsive="sm">
      <template v-slot:cell(actions)="row">
        <b-button size="sm" @click="onEditTenant(row.item)" class="mr-2">Edit</b-button>
        <b-button size="sm" @click="onDeleteTenant(row.item)" class="mr-2" variant="danger">Delete</b-button>
      </template>
    </b-table>
    <b-button size="sm" @click="showTenantModal=true; mode='create'; newCompanyOwner = $store.state.userObj.id;" class="mr-2" variant="success">Create Company</b-button>

    <!-- ONLY MODAL -->
    <b-modal v-model="showUserModal" title="Create/Edit User">
      <b-container fluid>
        <b-form @submit="onCreateOrEditUser">
          <b-row class="my-1">
            <b-col sm="4"><label>Display Name</label></b-col>
            <b-col sm="8">
              <b-form-input v-model="newUserDisplayName" type="text" placeholder="Max Müller" required/>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="4"><label>Email</label></b-col>
            <b-col sm="8">
              <b-form-input v-model="newUserMail" type="text" placeholder="test@test.de" required/>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="4"><label>New Password</label></b-col>
            <b-col sm="8">
              <b-form-input v-model="newUserPassword" type="password" placeholder=""/>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="4"><label>AssignedTenants</label></b-col>
            <b-col sm="8">
              <b-form-checkbox v-model="newUserAssignedTenants" v-for="tenant in tenants" :key="tenant.tenantId"
                :value="tenant.tenantId" type="checkbox"  :state="assignedTenantsState">
                {{ tenant.companyName }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="4"><label>isTennantAdmin</label></b-col>
            <b-col sm="8">
              <b-form-checkbox v-model="newUserisTennantAdmin" v-for="tenant in tenants" :key="tenant.tenantId"
                :value="tenant.tenantId" type="checkbox" :state="isTennantAdminState">
                {{ tenant.companyName }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="4"><label>Active Tenant </label></b-col>
            <b-col sm="8">           
              <div v-for="(tenant, index) in tenants" v-bind:key="tenant" v-bind:index="index">
                <b-form-radio v-model="newUserActiveTenant" name="some-radios" :value="tenant.tenantId" required>{{ tenant.companyName }}</b-form-radio>
              </div>
          
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="4"><label>Global Admin</label></b-col>
            <b-col sm="8">
              <b-form-checkbox v-model="newUserGlobalAdmin" type="checkbox">Admin</b-form-checkbox>
            </b-col>
          </b-row>

          <b-button type="submit" variant="primary" class="text-center mr-2" :disabled="disallowUserSubmit">Submit</b-button>
        </b-form>
      </b-container>

      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <b-modal v-model="showTenantModal" title="Create/Edit Company">
      <b-container fluid>
        <b-form @submit="onCreateOrEditTenant">
          <b-row class="my-1">
            <b-col sm="4"><label>Company Name</label></b-col>
            <b-col sm="8">
              <b-form-input v-model="newCompanyName" type="text" placeholder="123 GmbH" required/>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4"><label>Owner ID</label></b-col>
            <b-col sm="8">
              <b-form-input v-model="newCompanyOwner" type="text" placeholder="Owner ID" required />
            </b-col>
          </b-row>

          <b-button type="submit" variant="primary" class="text-center">Submit</b-button>
        </b-form>
      </b-container>

      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import * as utils from "../utils/utils";
//import router from "../router";

export default {
  name: "AdminView",
  data() {
    return {
      showTenantModal: false,
      newCompanyName: "",
      newCompanyOwner: "",

      showUserModal: false,
      mode: "create",
      editId: "",
      newUserDisplayName: "",
      newUserMail: "",
      newUserPassword: "",
      newUserAssignedTenants: [],
      newUserisTennantAdmin: [],
      newUserGlobalAdmin: false,
      newUserActiveTenant: "",

      isBusy: false,

      userfields: [
        { key: "id", label: "ID" },
        { key: "displayName", label: "Display Name" },
        { key: "email", label: "Email" },
        { key: "isGlobalAdmin", label: "Global Admin" },
        { key: "assignedTenants", label: "assignedTenants" },
        { key: "isTennantAdmin", label: "isTennantAdmin" },
        { key: "activeTenant", label: "Active Tenant" },
        { key: "actions", label: "Actions" }
      ],

      tenantfields: [
        { key: "tenantId", label: "ID" },
        { key: "companyName", label: "Name" },
        { key: "tenantOwner", label: "Owner" },
        { key: "actions", label: "Actions" }
      ],

      users: [],
      tenants: []
    };
  },
  computed: {
    assignedTenantsState() {
      return this.newUserAssignedTenants.length > 0
    },
    isTennantAdminState() {
      return this.newUserisTennantAdmin.length > 0
    },
    disallowUserSubmit() {
      return !this.assignedTenantsState || !this.isTennantAdminState
    }
  },
  methods: {
    //USER
    onCreateOrEditUser(evt) {
      evt.preventDefault();
      console.log(this.newUserisTennantAdmin)

      const data = {
        user: this.newUserDisplayName,
        email: this.newUserMail,
        isGlobalAdmin: this.newUserGlobalAdmin,
        assignedTenants: this.newUserAssignedTenants,
        isTennantAdmin: this.newUserisTennantAdmin,
        activeTenant: this.newUserActiveTenant,
      };
      console.log(data)


      //only update pw if necessary
      if (this.newUserPassword != "") {
        data.password = this.newUserPassword
      }

      if (this.mode === "create") {
        axios
          .post("/api/users/", data)
          .then(response => {
            this.$log.debug("create okay " + response.status);
            this.clearModals();
          })
          .catch(errors => {
            this.$log.error("create fail " + errors);
            this.$bvToast.toast(errors.message, {
              title: `API Error`,
              variant: "danger",
              solid: true
            });
          });
      } else {
        axios
          .patch("/api/users/" + this.editId, data)
          .then(response => {
            this.$log.debug("udapte okay " + response.status);
            this.clearModals();
          })
          .catch(errors => {
            this.$log.error("udapte fail " + errors);
            this.$bvToast.toast(errors.message, {
              title: `API Error`,
              variant: "danger",
              solid: true
            });
          });
      }
    },

    clearModals() {
      this.showUserModal = false;
      this.newUserDisplayName = "";
      this.newUserMail = "";
      this.newUserPassword = "";
      this.newUserAssignedTenants = [];
      this.newUserisTennantAdmin = [];
      this.newUserActiveTenant = "";
      this.editId = "";

      this.showTenantModal = false;
      this.newCompanyName = "";
      this.newCompanyOwner = "";


      this.refreshTables();
    },

    onEditUser(item) {
      this.mode = "edit";
      this.newUserDisplayName = item.displayName;
      this.newUserMail = item.email;
      this.newUserPassword = "";
      this.newUserGlobalAdmin = item.isGlobalAdmin;
      this.newUserAssignedTenants = item.assignedTenants;
      this.newUserisTennantAdmin = item.isTennantAdmin;
      this.newUserActiveTenant = item.activeTenant;
      this.editId = item.id;

      this.showUserModal = true;
    },

    onDeleteUser(item) {
      axios
        .delete("/api/users/" + item.id)
        .then(response => {
          this.$log.debug("delete okay " + response.status);
          this.refreshTables();
        })
        .catch(errors => {
          this.$log.error("delete fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });
    },

    //TENNANT STUFF
    onCreateOrEditTenant(evt) {
      evt.preventDefault();

      const data = {
        companyName: this.newCompanyName,
        tenantOwner: this.newCompanyOwner
      };

      if (this.mode == "create") {
        axios
          .post("/api/tenants/", data)
          .then(response => {
            this.$log.debug("create okay " + response.status);
            this.showTenantModal = false;
            this.mode = "create"
            this.newCompanyName = "";
            this.newCompanyOwner = "";

            this.refreshTables();
          })
          .catch(errors => {
            this.$log.error("create fail " + errors);
            this.$bvToast.toast(errors.message, {
              title: `API Error`,
              variant: "danger",
              solid: true
            });
          });

      } else if (this.mode == "edit") {
        axios
          .patch("/api/tenants/" + this.editId, data)
          .then(response => {
            this.$log.debug("update okay " + response.status);
            this.showTenantModal = false;
            this.mode = "create"
            this.newCompanyName = "";
            this.newCompanyOwner = "";

            this.refreshTables();
          })
          .catch(errors => {
            this.$log.error("update fail " + errors);
            this.$bvToast.toast(errors.message, {
              title: `API Error`,
              variant: "danger",
              solid: true
            });
          });
      }
    },

    onEditTenant(item) {
      this.mode = "edit";
      this.editId = item.tenantId;
      this.newCompanyName = item.companyName;
      this.newCompanyOwner = item.tenantOwner;


      this.showTenantModal = true;
    },

    onDeleteTenant(item) {
      console.log(item)

      axios
        .delete("/api/tenants/" + item.tenantId)
        .then(response => {
          this.$log.debug("delete okay " + response.status);
          this.refreshTables();
        })
        .catch(errors => {
          this.$log.error("delete fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });
    },

    refreshTables() {
      this.isBusy = true;

      axios
        .get("/api/users/")
        .then(response => {
          this.$log.debug("Users");
          this.$log.debug(response.data);
          this.users = response.data.users;
        })
        .catch(errors => {
          this.$log.error("get users fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });

      axios
        .get("/api/tenants/")
        .then(response => {
          this.$log.debug("Tenants");
          this.$log.debug(response.data);
          this.tenants = response.data.tenants;
        })
        .catch(errors => this.handleAPIError(errors));

      this.isBusy = false;
    },

    handleAPIError(errors) {
      this.$log.error("api call fail " + errors);
      this.$bvToast.toast(errors.message, {
        title: `API Error`,
        variant: "danger",
        solid: true
      });
    }
  },
  async mounted() {
    utils.checkForLoginAndRedirect(this.$router, this.$store);
    await this.$store.state.userObj

    console.log(this.$store.state)

    this.clearModals(); //does refresh tables
  }
};
</script>

<style scoped>
#main {
  width: 85%;
  margin: auto;
}
</style>
