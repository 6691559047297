import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import Start from '@/components/StartPage'
import TaskList from '@/components/TaskList'
import TaskView from '@/components/TaskView'
import UserView from '@/components/UserView'
import AdminView from '@/components/AdminView'
import DatanormView from '@/components/DatanormView'


Vue.use(Router)

export default new Router({
 /* mode: 'history', */
  routes: [
    {
      path: '/',
      name: 'StartPage',
      component: Start
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/tasklist',
      name: 'Tasklist',
      component: TaskList
    }, 
    {
      path: '/task/:id',
      name: 'TaskView',
      component: TaskView,
      props: true,
    }, 
    {
      path: '/userview',
      name: 'UserView',
      component: UserView
    }, 
    {
      path: '/admin',
      name: 'AdminView',
      component: AdminView
    }, 
    {
      path: '/datanorm',
      name: 'DatanormView',
      component: DatanormView
    }
  ]
})
