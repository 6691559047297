<template>
  <div id="main">
    <h2>{{ $t('tasks.tasklist') }}</h2>
    <b-table
      :items="getActiveTasks"
      :fields="activefields"
      :busy="isBusy"
      striped
      hover
      responsive="sm"
    >
      <template v-slot:cell(createdAt)="row">{{ new Date(row.item.createdAt).toLocaleString() }}</template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          @click="onOpen(row.item)"
          class="mr-2"
          variant="success"
        >{{ $t('common.open') }}</b-button>
        <b-button
          size="sm"
          @click="onArchiveChange(row.item, true)"
          class="mr-2"
        >{{ $t('common.archive') }}</b-button>
        <b-button
          size="sm"
          @click="onDeleteTask(row.item, $evt)"
          class="mr-2"
          variant="danger"
        >{{ $t('common.delete') }}</b-button>
      </template>
    </b-table>
    <b-button variant="primary" size="sm" @click="showModal=true">{{ $t('tasks.create_task') }}</b-button>
    <br />
    <br />
    <br />
    <br />
    <b-button v-b-toggle.collapse-1>{{ $t('common.archive') }}</b-button>
    <b-collapse id="collapse-1" class="mt-2">
      <b-card fluid>
        <div class="col-xs-6" align="center">
          <b-table
            class="table w-25"
            align="center"
            :items="getInactiveTasks"
            :fields="archivefields"
            striped
            hover

          >
            <template v-slot:cell(actions)="row">
              <b-button
                size="sm"
                @click="onOpen(row.item)"
                class="mr-2"
                variant="success"
              >{{ $t('common.open') }}</b-button>
              <b-button
                size="sm"
                @click="onArchiveChange(row.item, false)"
                class="mr-2"
              >{{ $t('common.unarchive') }}</b-button>
              <b-button
                size="sm"
                @click="onDeleteTask(row.item, $evt)"
                class="mr-2"
                variant="danger"
              >{{ $t('common.delete') }}</b-button>
            </template>
          </b-table>
        </div>
      </b-card>
    </b-collapse>

    <!-- ONLY MODAL -->
    <b-modal v-model="showModal" :title="$t('tasks.create_task')">
      <b-container fluid class="m-2 float-center">
        <b-form @submit="onCreateTask">
          <b-form-input v-model="createTaskName" type="text" :placeholder="$t('tasks.task_name')"></b-form-input>
          <div class="col-12 text-center">
            <b-button class="mt-2 mr-2" type="submit" variant="primary" >{{ $t('common.submit') }}</b-button>
          </div> 
        </b-form>
      </b-container>

      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import router from "../router";
import * as utils from "../utils/utils";

export default {
  name: "TaskList",
  data() {
    return {
      //create model
      showModal: false,
      createTaskName: "",

      //table stuff
      isBusy: false,

      activefields: [
        { key: "createdAt", label: this.$t('tasks.created'), sortable: true },
        { key: "taskName", label: this.$t('tasks.task_name'), sortable: true },
        { key: "customerName", label: this.$t('tasks.customer'), sortable: true },
        { key: "assignee", label: this.$t('tasks.assignee'), sortable: true },
        { key: "status", label: this.$t('tasks.status'), sortable: true },
        { key: "actions", label: this.$t('common.actions') }
      ],
      archivefields: [
        { key: "taskName", label: this.$t('tasks.task_name') },
        { key: "actions", label: this.$t('common.actions') }
      ],
      tasks: []
    };
  },
  computed: {
    getActiveTasks() {
      return this.tasks.filter(task => !task.isArchived);
    },
    getInactiveTasks() {
      return this.tasks.filter(task => task.isArchived);
    }
  },
  methods: {
    onOpen(item) {
      router.push({ name: "TaskView", params: { taskId: item._id } });
    },

    onArchiveChange(item, isArchived) {
      item.isArchived = isArchived;
      this.$log.error(this.tasks);

      const data = {
        task: item
      };

      axios
        .patch("/api/tasks/" + item._id, data)
        .then(response => {
          this.$log.debug("save okay " + response);
          this.$bvToast.toast("Everything is good", {
            title: this.$t('tasks.task_saved'),
            solid: true
          });

          this.refreshTable();
        })
        .catch(errors => {
          this.$log.error("update task fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });

      //this.refreshTable();
    },

    onCreateTask(evt) {
      evt.preventDefault();

      const data = {
        taskName: this.createTaskName
      };
      axios
        .post("/api/tasks/", data)
        .then(response => {
          this.$log.debug("create okay " + response.status);
          this.showModal = false;
          this.createTaskName = "";

          this.refreshTable();
        })
        .catch(errors => {
          this.$log.error(errors);

          this.$log.error("create task fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });
    },

    onDeleteTask(item, event) {
      axios
        .delete("/api/tasks/" + item._id)
        .then(response => {
          this.$log.debug("delete okay " + response.status);

          this.refreshTable();
        })
        .catch(errors => {
          this.$log.error("delete task fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });

      this.refreshTable();
      event.target.blur();
    },

    refreshTable() {
      this.isBusy = true;

      axios
        .get("/api/tasks/")
        .then(response => {
          this.$log.debug("Tasks");
          this.$log.debug(response.data);
          this.tasks = response.data.tasks;
        })
        .catch(errors => {
          if (errors.response.status == 401) return;

          this.$log.error("get taskview fail " + errors);
          this.$bvToast.toast(errors.message, {
            title: `API Error`,
            variant: "danger",
            solid: true
          });
        });

      this.isBusy = false;
    }
  },
  beforeMount() {
    utils.checkForLoginAndRedirect(this.$router, this.$store);
  },

  mounted() {
    this.refreshTable();
  }
};
</script>

<style scoped>
#main {
  width: 95%;
  margin: auto;
}
</style>
