<template>
  <div id="main">
    <h2>Tenant Selection</h2>

    <b-form @submit="onSubmit">
      <b-form-group label="Select active tenant">
        <div v-for="(row, index) in tenantIDs" v-bind:key="row" v-bind:index="index">
          <b-form-radio v-model="selected" name="some-radios" :value="row">{{ row }}</b-form-radio>
        </div>
      </b-form-group>
      <b-button type="submit" variant="primary">{{ $t('common.submit') }}</b-button>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TenantSelection",
  data() {
    return {
      selected: ""
    };
  },
  computed: {
    tenantIDs() {
      if (this.$store.state.userObj) {
        return this.$store.state.userObj.assignedTenants;
      } else {
        return []
      }
        
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      let data = {
        tenantId: this.selected
      };
      axios
        .post("/api/auth/selectTenant", data)
        // eslint-disable-next-line no-unused-vars
        .then(_response => {
          this.$bvToast.toast("Active tenant was updated", {
            title: `Info`,
            solid: true
          });
        })
        .catch(errors => {
          this.$log.error("selectTenant fail " + errors);
        });
    }
  },
  mounted() {
    if (this.$store.state.userObj) {
      console.log(this.$store.state.userObj);
      console.log(this.tenantIDs);

      this.selected = this.$store.state.userObj.activeTenant;
    }
  }
};
</script>

<style scoped>
#main {
  width: 50%;
  margin: auto;
}
</style>
