<template>
  <div id="main">
    <h2>User</h2>
    {{ this.userObj }}

    <TenantSelection></TenantSelection>
  </div>
</template>

<script>
import TenantSelection from "./TenantSelection";

export default {
  name: "UserView",
  components: {
    TenantSelection
  },

  data() {
    return {};
  },
  computed: {
    userObj() {
      return this.$store.state.userObj;
    }
  },
  methods: {},
  mounted() {}
};
</script>

<style scoped>
#main {
  width: 50%;
  margin: auto;
}
</style>
